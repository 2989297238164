import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css/bundle';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { PaginationOptions } from 'swiper/types/modules/pagination';

let figureOffsetHeight: number;

const calculateArrowOffset = (swiperInstance: Swiper) => {
  const paginationBtn =
    swiperInstance.el?.querySelector<HTMLElement>('.slider-button');

  if (!paginationBtn) return;

  const arrowOffsetRaw = figureOffsetHeight / 2;
  const paginationBtnHalfSize = paginationBtn.offsetHeight / 2;
  const arrowOffset = `${arrowOffsetRaw - paginationBtnHalfSize}px`;
  swiperInstance.el.style.setProperty('--arrow-top', arrowOffset);
};

const checkAndCalculateArrowOffset = (swiperInstance: Swiper) => {
  const newOffset = swiperInstance.el.querySelector('figure')?.offsetHeight;

  if (typeof newOffset === 'undefined') return;

  if (newOffset != figureOffsetHeight) {
    figureOffsetHeight = newOffset;
    calculateArrowOffset(swiperInstance);
  }
};

const defaultOptions: SwiperOptions = {
  modules: [Navigation, Pagination],
  spaceBetween: 8,
  slidesPerView: 2.2,
  navigation: {
    nextEl: '.slider-next',
    prevEl: '.slider-prev'
  },
  pagination: {
    el: '.slider-pagination',
    type: 'bullets'
  }
};

const autoplaySettings = (selector: string): SwiperOptions['autoplay'] => {
  const slider = document.querySelector<HTMLElement>(selector);
  const autoplay = slider?.dataset.autoplay;
  const delay = tryParseInt(slider?.getAttribute('data-speed'), 0);

  return autoplay === 'true'
    ? {
        delay,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      }
    : false;
};

const initSlider = (selector: string, options: SwiperOptions = {}) => {
  const swipers = document.querySelectorAll<HTMLElement>(selector);

  swipers.forEach((slider) => {
    // Remove loading class from container
    slider.classList.remove('is-loading');

    const slidesTablet = tryParseInt(slider.dataset.slidesTablet, 4);
    const slidesDesktop = tryParseInt(slider.dataset.slidesDesktop, 4);

    const sliderPagination: { pagination: PaginationOptions | boolean } =
      slider.dataset.slidesPagination === 'false'
        ? { pagination: false }
        : {
            pagination: {
              el: '.slider-pagination',
              type: 'bullets'
            }
          };

    const dataOptions: SwiperOptions = {
      ...sliderPagination,
      breakpoints: {
        768: {
          slidesPerView: slidesTablet,
          spaceBetween: 16
        },
        1024: {
          slidesPerView: slidesDesktop,
          spaceBetween: 16
        }
      },
      on: {
        init(swiperInstance) {
          // TODO: Don't use element selector, this code breaks if product image is not wrapped in a <figure> (!!!)
          const firstImageContainer = swiperInstance.el.querySelector('figure');
          if (!firstImageContainer) return;
          figureOffsetHeight = firstImageContainer.offsetHeight;
          calculateArrowOffset(swiperInstance);
          window.addEventListener('resize', () =>
            checkAndCalculateArrowOffset(swiperInstance)
          );
        },
        destroy(swiperInstance) {
          window.removeEventListener('resize', () =>
            checkAndCalculateArrowOffset(swiperInstance)
          );
        }
      },
      ...options
    };

    const swiperOptions = { ...defaultOptions, ...dataOptions, ...options };
    new Swiper(slider, swiperOptions);
  });
};

export const loadSlider = (selector: string, options: SwiperOptions) => {
  const itemsSelector = `${selector} .swiper-slide`;

  if (document.querySelectorAll(itemsSelector).length) {
    initSlider(selector, options);
  } else {
    // Break interval if it reaches +5 sec (20 * 250ms)
    let tries = 0;
    const maxTries = 20;
    const intervalDelay = 250;

    const intervalId = setInterval(() => {
      tries++;
      if (document.querySelectorAll(itemsSelector).length) {
        clearInterval(intervalId);
        initSlider(selector, options);
      } else if (tries === maxTries) {
        clearInterval(intervalId);
      }
    }, intervalDelay);
  }
};

export const loadSlideshow = (selector: string) => {
  const slider = document.querySelector<HTMLElement>(selector);
  const autoplay = autoplaySettings(selector);

  const sliderPagination: { pagination: PaginationOptions | boolean } =
    slider?.dataset.slidesPagination === 'false'
      ? { pagination: false }
      : {
          pagination: {
            el: selector + ' .slider-pagination',
            type: 'bullets'
          }
        };

  const slideshowOptions: SwiperOptions = {
    ...sliderPagination,
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 1,
    autoplay,
    on: {},
    breakpoints: {},
    speed: 500
  };

  loadSlider(selector, slideshowOptions);
};

const tryParseInt = (value: string | null | undefined, fallBack: number) => {
  if (value === null || typeof value === 'undefined') {
    return fallBack;
  }

  const integer = Number.parseInt(value);

  return !Number.isNaN(integer) ? integer : fallBack;
};
